import {
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useApi } from "@/common/api";
import { api } from "@/common/api";
import { Competition, Leaderboard, LeaderboardResponse } from "../types";

interface CompetitionsResponse {
  payload: Competition[];
}

interface SearchResponse {
  competitions: Competition[];
  total: number;
}

export interface CompetitionFormData {
  name: string;
  public: boolean;
  allowLateEntry: boolean;
  competitionId: number;
  entryFee: number | null;
  prizeText: string;
}

interface CreateCompetitionParams {
  name: string;
  public: boolean;
  allowLateEntry: boolean;
  entryFee: number | null;
  prizeText: string;
}

interface CompetitionResponse {
  competition: Competition;
}

// Import the tip types from the MatchList component or define them here
export type SavedTip = {
  matchId: number;
  teamId: number;
  margin?: number;
  value: number;
  correct: boolean | null;
  score: number;
  autoPick: boolean;
  saved: true;
};

export type Tip = {
  matchId: number;
  teamId: number | null;
  margin?: number | null;
  expectedValue: number;
  saved: false;
};

export type TipError = {
  matchId: number;
  message: string;
};

export type SaveTipsResult = {
  savedTips?: SavedTip[];
  tipErrors?: TipError[];
  error?: { code: number; message: string };
};

// Base service functions
function createTippingService(api: ReturnType<typeof useApi>) {
  return {
    fetchMyCompetitions: async (includeInactive: boolean = false) => {
      const response = await api.get<CompetitionsResponse>(
        `/tipping/competitions?active=${!includeInactive}`
      );
      return response.payload;
    },

    joinCompetition: async (code: string): Promise<Competition> => {
      try {
        const response = await api.post<Competition>(
          "/tipping/competitions/join",
          {
            joinCode: code,
          }
        );
        return response;
      } catch (error) {
        // Preserve the original error structure with the detailed message
        console.error("Join competition error:", error);
        throw error; // Pass the error through to be handled by useFormSubmit
      }
    },

    searchCompetitions: async (
      query: string,
      offset: number = 0,
      limit: number = 20
    ) => {
      return api.get<SearchResponse>(
        `/tipping/competitions/search?query=${query}&offset=${offset}&limit=${limit}`
      );
    },

    fetchLeaderboard: async (
      competitionId: number,
      offset: number = 0,
      limit: number = 20
    ) => {
      const response = await api.get<LeaderboardResponse>(
        `/tipping/leaderboard?competition_id=${competitionId}&offset=${offset}&limit=${limit}&include_user=1`
      );

      return response.payload;
    },

    createCompetition: async (
      params: CreateCompetitionParams
    ): Promise<Competition> => {
      const response = await api.post<CompetitionResponse>(
        "/tipping/competitions",
        params
      );
      return response.competition;
    },

    updateCompetition: async (
      competitionId: number,
      params: CreateCompetitionParams
    ): Promise<Competition> => {
      const response = await api.put<CompetitionResponse>(
        `/tipping/competitions/${competitionId}`,
        params
      );
      return response.competition;
    },

    getCompetition: async (competitionId: number): Promise<Competition> => {
      try {
        console.log(
          `[API] Fetching competition details for ID: ${competitionId}`
        );
        const response = await api.get<CompetitionResponse>(
          `/tipping/competitions/${competitionId}`
        );

        // Validate the response has the expected structure
        if (!response || !response.competition) {
          console.error(
            `[API] Invalid response format for competition ${competitionId}:`,
            response
          );
          throw new Error(
            `Failed to load competition ${competitionId}: invalid response format`
          );
        }

        console.log(
          `[API] Successfully fetched competition ${competitionId}:`,
          response.competition
        );
        return response.competition;
      } catch (error) {
        console.error(
          `[API] Error fetching competition ${competitionId}:`,
          error
        );
        throw error;
      }
    },

    getAvailableCompetitions: async () => {
      const response = await api.get<{
        competitions: { id: number; name: string }[];
      }>("/tipping/availableCompetitions");
      return response;
    },

    // New functions for tips
    fetchTips: async (matchIds: number[]): Promise<SavedTip[]> => {
      try {
        const response = await api.post<{ payload: any[] }>(
          "/r/tipping/list",
          {
            matchIds,
          },
          { authenticated: true }
        );

        // Ensure all tips have saved=true
        return (response.payload || []).map((tip) => ({
          ...tip,
          saved: true,
        }));
      } catch (error) {
        console.error("Error fetching tips:", error);
        throw error;
      }
    },

    saveTips: async (tips: Tip[]): Promise<SaveTipsResult> => {
      try {
        const response = await api.post<{
          payload: SavedTip[];
          errors?: TipError[];
        }>("/r/tipping/tip", {
          tips: tips.map((tip) => ({
            ...tip,
            value: tip.expectedValue,
          })),
        });

        return {
          savedTips: response.payload || [],
          tipErrors: (response.errors || []).filter((error) =>
            error.hasOwnProperty("matchId")
          ),
        };
      } catch (error: any) {
        console.error("Error saving tips:", error);

        // Handle authentication error
        if (error.status === 401) {
          return {
            savedTips: [],
            error: {
              code: 401,
              message: "You need to be logged in to save tips",
            },
          };
        }

        return {
          savedTips: [],
          error: {
            code: 0,
            message: "An unexpected error occurred",
          },
        };
      }
    },
  };
}

// Hook to use the service
export function useTippingService() {
  const api = useApi();
  return createTippingService(api);
}

// React Query hooks for data fetching
export function useCompetitions(includeInactive: boolean = false) {
  const service = useTippingService();

  return useQuery({
    queryKey: ["competitions", includeInactive],
    queryFn: () => service.fetchMyCompetitions(includeInactive),
  });
}

export function useJoinCompetition() {
  const queryClient = useQueryClient();
  const service = useTippingService();

  return useMutation({
    mutationFn: service.joinCompetition,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["competitions"] });
    },
  });
}

export function useSearchCompetitions(query: string) {
  const service = useTippingService();

  return useInfiniteQuery<SearchResponse>({
    queryKey: ["competitions", "search", query],
    queryFn: ({ pageParam = 0 }) =>
      service.searchCompetitions(query, pageParam as number),
    getNextPageParam: (lastPage, allPages) => {
      const totalFetched = allPages.reduce(
        (sum, page) => sum + page.competitions.length,
        0
      );
      return totalFetched < lastPage.total ? totalFetched : undefined;
    },
    initialPageParam: 0,
    enabled: query.length > 0,
  });
}

export function useLeaderboard(competitionId: number) {
  const service = useTippingService();

  return useQuery({
    queryKey: ["leaderboard", competitionId],
    queryFn: () => service.fetchLeaderboard(competitionId),
    enabled: competitionId > 0,
  });
}

export function useCreateCompetition() {
  const queryClient = useQueryClient();
  const service = useTippingService();

  return useMutation({
    mutationFn: service.createCompetition,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["competitions"] });
    },
  });
}

export function useUpdateCompetition(competitionId: number) {
  const queryClient = useQueryClient();
  const service = useTippingService();

  return useMutation({
    mutationFn: (data: CreateCompetitionParams) =>
      service.updateCompetition(competitionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["competitions"] });
      queryClient.invalidateQueries({
        queryKey: ["competitions", "detail", competitionId],
      });
    },
  });
}

export function useCompetition(competitionId: number) {
  const service = useTippingService();

  return useQuery({
    queryKey: ["competitions", "detail", competitionId],
    queryFn: async () => {
      try {
        // Only make the API call if we have a valid competition ID
        if (!competitionId || isNaN(competitionId)) {
          console.error("Invalid competition ID provided:", competitionId);
          throw new Error("Invalid competition ID");
        }

        return await service.getCompetition(competitionId);
      } catch (error) {
        console.error(`Error fetching competition ${competitionId}:`, error);
        // Rethrow the error to let React Query handle it
        throw error;
      }
    },
    enabled: !!competitionId && !isNaN(competitionId),
    retry: 2, // Try up to 2 additional times if the request fails
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
  });
}

export function useAvailableCompetitions() {
  const service = useTippingService();

  return useQuery({
    queryKey: ["availableCompetitions"],
    queryFn: () => service.getAvailableCompetitions(),
  });
}

// New React Query hooks for tips
export function useTips(matchIds: number[]) {
  const service = useTippingService();

  return useQuery({
    queryKey: ["tips", matchIds],
    queryFn: () => service.fetchTips(matchIds),
    enabled: matchIds.length > 0,
    retry: 2,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
  });
}

export function useSaveTips() {
  const queryClient = useQueryClient();
  const service = useTippingService();

  return useMutation({
    mutationFn: service.saveTips,
    onSuccess: (result, variables) => {
      // Get the matchIds from the variables (unsaved tips)
      const matchIds = variables.map((tip) => tip.matchId);

      // If we have matchIds, invalidate the corresponding tips queries
      if (matchIds.length > 0) {
        // Invalidate any tips queries that might include these matchIds
        queryClient.invalidateQueries({
          queryKey: ["tips"],
          predicate: (query) => {
            // Check if this is a tips query
            if (query.queryKey[0] !== "tips") return false;

            // If it's just ["tips"] with no matchIds, invalidate it
            if (query.queryKey.length === 1) return true;

            // If it's ["tips", matchIds], check if any matchIds overlap
            if (Array.isArray(query.queryKey[1])) {
              const queryMatchIds = query.queryKey[1] as number[];
              return queryMatchIds.some((id) => matchIds.includes(id));
            }

            return false;
          },
        });
      }
    },
  });
}

// Server-side functions
export async function getLeaderboard(
  competitionId: number,
  page: number = 0,
  limit: number = 20
) {
  // Always include_user=1 parameter to ensure consistent user data
  const response = await api.get<LeaderboardResponse>(
    `/tipping/leaderboard?competition_id=${competitionId}&offset=${
      page * limit
    }&limit=${limit}&include_user=1`
  );
  return response.payload;
}
